import React from "react";
import PropTypes from "prop-types";
import Link from "next/link";
import { useRouter } from "next/router";
import {
  Box,
  Button,
  Form,
  Text,
} from "grommet";
import {
  AnchoredLayout,
  EmailFormField,
  Header,
  Logo,
  Message,
  Divider,
} from "@/cloverleaf-ui/components";
import { validate } from "@/cloverleaf-ui/utils";
import { AnchorLink } from "@/components/v2/AnchorLink";
import { useLocale, VALIDATE_EMAIL_STATUS } from "@/lib/hooks";
import { Trans } from "@/lib/hooks/useLocale";

function SignInPage({
  error = undefined,
  loading = false,
  onCancel = undefined,
  onSubmit = () => undefined,
  organization = undefined,
  showCreateAccount = true,
}) {
  const router = useRouter();

  const { t } = useLocale();

  const queryParams = router.query;

  const emailRef = React.useRef(null);

  const [email, setEmail] = React.useState(router?.query?.email ?? "");

  React.useEffect(() => {
    if (emailRef && emailRef.current) {
      emailRef.current.focus();

      setEmail(emailRef.current.value);
    }
  }, [emailRef]);

  const handleOnChange = (event) => {
    setEmail(event.target.value);
  };

  /**
   * value is a destructred object of all <Form> values as a fieldName:value key pair
   * i.e., here onSubmit is called with the object, { email: email@dot.com }
   */
  const handleOnSubmit = ({ value }) => {
    onSubmit(value);
  };

  const handleOnCancel = () => {
    if (typeof onCancel === "function") {
      onCancel();
    }
  };

  return (
    <AnchoredLayout>
      <Box align="center" justify="center">
        <Logo src={organization?.bannerLogo} width="100%" />
      </Box>
      <Header title={t("sign-in")} />
      {error && (
        error === VALIDATE_EMAIL_STATUS.INVALID ? (
          <Message
            alignSelf="center"
            kind="warning"
            margin="small"
          >
            <Trans i18nKey="common:suggest_create_account" t={t}>
              Would you like to
              <AnchorLink
                color="text-link"
                href={{
                  pathname: "/signup",
                  query: { ...queryParams, email },
                }}
              >
                create an account with this address
              </AnchorLink>
              ?
            </Trans>
          </Message>
        ) : (
          <Message alignSelf="center" kind="error" margin="small">
            {error}
          </Message>
        )
      )}
      <Box fill="horizontal">
        <Form onSubmit={handleOnSubmit}>
          <EmailFormField
            ref={emailRef}
            autoComplete="username"
            autoFocus
            label={t("input_email_field")}
            onChange={handleOnChange}
            validate={[validate.required]}
            value={email}
          />
          <Box
            direction="row"
            gap="medium"
            justify="center"
            pad={{ top: "small" }}
          >
            {onCancel && (
              <Button
                disabled={loading}
                fill="horizontal"
                label={t("button.cancel")?.toUpperCase()}
                onClick={handleOnCancel}
                secondary
              />
            )}
            <Button
              disabled={loading || !email}
              fill="horizontal"
              label={t("button.sign_in")?.toUpperCase()}
              primary
              type="submit"
            />
          </Box>
        </Form>
      </Box>
      {showCreateAccount && (
        <>
          <Divider color="grey400" margin={{ vertical: "large" }} />
          <Box
            direction="column"
            gap="small"
            justify="center"
          >
            <Text alignSelf="center">
              {t("dont_have_account")}
            </Text>
            <Link
              href={{
                pathname: "/trial/get-started",
                query: { ...queryParams },
              }}
              passHref
              legacyBehavior
            >
              <Button
                label={t("create_free_account").toUpperCase()}
                secondary
              />
            </Link>
          </Box>
        </>
      )}
    </AnchoredLayout>
  );
}

SignInPage.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  organization: PropTypes.shape({
    alias: PropTypes.string,
    bannerLogo: PropTypes.string,
    name: PropTypes.string,
    ssoUrl: PropTypes.string,
    whitelist: PropTypes.array,
    whitelistEnabled: PropTypes.bool,
  }),
  showCreateAccount: PropTypes.bool,
};

export default SignInPage;
